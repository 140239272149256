import React from 'react'
import PropTypes from 'prop-types'
import { navigate, graphql } from 'gatsby'
import Layout from '@organisms/layout'
import SEO from '@atoms/seo'
import Heading from '@particles/heading'
import Cards from '@molecules/cards'
import List from '@molecules/list'
import Pagination from '@atoms/pagination'
import Text from '@particles/text'

import { media, useMedia } from '@breakpoints'
import styled from 'styled-components'

const AllRolesPageStyle = styled(Layout)`
  ${media.tablet`
  .side-grid {
    grid-template-columns: 2.5fr 4.2fr;
  }
  `}

  ${media.phablet`

  .side-grid {
    grid-template-columns: 1fr;
  }


  `}
`

const AllRolesPage = ({
  data,
  pageContext: {
    currentPage,
    numPages,
    relativePath,
    roles,
    roleName,
    totalMetrics
  },
  location
}) => {
  const metrics = data.metrics.edges
  const isPhablet = useMedia('phablet')

  return (
    <AllRolesPageStyle location={location}>
      <SEO title={`${roleName} Metrics`} location={location} />
      <Heading center margin="T4" h1 bottom={1}>
        {roleName || 'All Metrics'}
      </Heading>
      <Layout.Grid
        className="side-grid"
        margin="T4.375"
        gridTemplate="1fr 4.3fr"
        gap={3.75}
      >
        <List title="Roles" collapsed={isPhablet}>
          <List.Item
            key="all-metrics"
            text={totalMetrics}
            active={roleName === undefined}
            onClick={() => {
              navigate('/all-metrics')
            }}
          >
            All
          </List.Item>
          {roles &&
            roles.map((v, i) => {
              return (
                <List.Item
                  key={`${v.data}-${i}`}
                  text={v.number}
                  active={v.data === roleName}
                  onClick={() => {
                    navigate(v.url)
                  }}
                >
                  {v.data}
                </List.Item>
              )
            })}
        </List>
        <div>
          <Layout.Grid fixed={278} gap={1.25} margin="T0 L0">
            {metrics.map(({ node }, i) => {
              return (
                <Cards.Metric
                  key={`${node.name}-${i}`}
                  header={node.name}
                  auto="true"
                  hover="true"
                  action={(ev) => {
                    ev.preventDefault()
                    navigate(node.slug)
                  }}
                  buttonAction={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate(node.slug + '#track')
                  }}
                  link={node.slug}
                >
                  {node.description && <Text>
                    {node.description.substring(0, 60).trim()}...
                  </Text>}
                </Cards.Metric>
              )
            })}
          </Layout.Grid>
        </div>
      </Layout.Grid>
      <Layout.Grid margin="T4.375" gridTemplate="1fr 4.3fr" gap={3.75}>
        <span />
        <Pagination
          relativePath={relativePath}
          currentPage={currentPage}
          totalPages={numPages}
        />
      </Layout.Grid>
    </AllRolesPageStyle>
  )
}
AllRolesPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.any,
  location: PropTypes.any
}
export default AllRolesPage

export const pageQuery = graphql`
  query AllRolesQuery($skip: Int!, $limit: Int!, $filter: String) {
    metrics: allMetric(
      skip: $skip
      limit: $limit
      filter: {role: {elemMatch: {directus_id: {glob: $filter}}}, status: {eq: "published"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          status
          directus_id
          name
          description
          slug
        }
      }
    }
  }
`
